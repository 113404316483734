import React, { useEffect, useState } from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import instance from "../constant/instance";

const OrderDetails = () => {
    const { id } = useParams()
    const [orderData, setOrderData] = useState({})

    const getOrderDetails = async() =>{
        try{
          const { data }  = await instance.get(`/order/${id}`)
          console.log(data)
          setOrderData({...data.data}) 
        } catch(err) {
           console.log(err)
        }
    }


    useEffect(() =>{
        getOrderDetails()
    },[])

   

  return (
    <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Field</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Transaction ID</TableCell>
          <TableCell>{orderData?.transactionId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Order ID</TableCell>
          <TableCell>{orderData?.orderId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Total Items</TableCell>
          <TableCell>{orderData?.totalItems}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Currency</TableCell>
          <TableCell>{orderData?.currency}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Status</TableCell>
          <TableCell>{orderData?.status}</TableCell>
        </TableRow>
        {/* Cart Data Mapping */}
        {orderData?.cartData && orderData?.cartData.map((item, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>{item?.name || "N/A"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Quantity</TableCell>
                <TableCell>{item?.quantity || "N/A"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Price</TableCell>
                <TableCell>{item?.price || "N/A"}</TableCell>
              </TableRow>
           
           {item.designId && (
      <>
        <TableRow>
          <TableCell>Custom Front Image</TableCell>
          <TableCell>
          <a href={item?.designId?.custom_image}  target="_blank"><img src={item?.designId?.custom_image} alt="Custom Front" style={{ maxWidth: "100px" }} /></a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Custom Back Image</TableCell>
          <TableCell>
          <a href={item?.designId?.custom_image_back}  target="_blank"><img src={item?.designId?.custom_image_back} alt="Custom Back" style={{ maxWidth: "100px" }} /></a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Custom Left Image</TableCell>
          <TableCell>
          <a href={item?.designId?.custom_image_left}  target="_blank"><img src={item?.designId?.custom_image_left} alt="Custom Left" style={{ maxWidth: "100px" }} /></a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Custom Right Image</TableCell>
          <TableCell>
            <a href={item?.designId?.custom_image_right}  target="_blank"><img src={item?.designId?.custom_image_right}  alt="Custom Right" style={{ maxWidth: "100px" }} /></a>
          </TableCell>
        </TableRow>
      </>
    )}
          </React.Fragment>
          ))}   
          <TableRow>
          <TableCell>Shipping Address</TableCell>
          <TableCell>
          {orderData && orderData.shipping_details && orderData.shipping_details.length > 0
    ? `${orderData.shipping_details[0].address.line1}, ${orderData.shipping_details[0].address.city}, ${orderData.shipping_details[0].address.state}, ${orderData.shipping_details[0].address.postal_code}, ${orderData.shipping_details[0].address.country}`
    : "No Shipping Details"}
          </TableCell>
        </TableRow>
        <TableRow>
  <TableCell>Shipping Name</TableCell>
  <TableCell>
    {orderData?.shipping_details?.length > 0 ? orderData.shipping_details[0].name : "N/A"}
  </TableCell>
</TableRow>
<TableRow>
  <TableCell>Billing Address</TableCell>
  <TableCell>
    {orderData?.billing_Details?.length > 0
      ? `${orderData.billing_Details[0].address.line1}, ${orderData.billing_Details[0].address.city}, ${orderData.billing_Details[0].address.state}, ${orderData.billing_Details[0].address.postal_code}, ${orderData.billing_Details[0].address.country}`
      : "No Billing Details"}
  </TableCell>
</TableRow>
<TableRow>
  <TableCell>Billing Name</TableCell>
  <TableCell>
    {orderData?.billing_Details?.length > 0 ? orderData.billing_Details[0].name : "N/A"}
  </TableCell>
</TableRow>

<TableRow>
  <TableCell>Email</TableCell>
  <TableCell>
    {orderData?.billing_Details?.length > 0 ? orderData.billing_Details[0].email : "N/A"}
  </TableCell>
</TableRow>
<TableRow>
  <TableCell>Phone</TableCell>
  <TableCell>
    {orderData?.billing_Details?.length > 0 ? orderData.billing_Details[0].phone : "N/A"}
  </TableCell>
</TableRow>

        <TableRow>
          <TableCell>Created At</TableCell>
          <TableCell>{new Date(orderData.createdAt).toLocaleString()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Updated At</TableCell>
          <TableCell>{new Date(orderData.updatedAt).toLocaleString()}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  );
};

export default OrderDetails;
